<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
		   <span v-if="active == 'dist'"> 可直接将低值易耗品发放给员工使用的操作，派发后，耗材的库存数量相应减少。</span>
		   <span v-if="active == 'cancl'">员工领取耗材后，未使用完，管理员将剩余耗材退还到公司仓库，退库后，耗材的库存数量相应增加。</span>
         </div>   
		   <el-tabs v-model="active" @tab-click="handleClick">
		     <el-tab-pane label="派发" name="dist"></el-tab-pane>
		     <el-tab-pane label="退库" name="cancl"></el-tab-pane>
		   </el-tabs>
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from '../Common/ConsumableTable.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
		  active:'dist',
		  type:2
      }
   },
   created(){
	  
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/CseDist/index',
				add:'/CseDist/add',
				edit:'/CseDist/edit',
				del:'/CseDist/del',
				export:'/CseDist/export'
			} 
			this.$refs.c_table.cardName =  '耗材派发单'
			// this.$refs.c_table.cardType = 3
			this.$refs.c_table.storageType = 2
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		handleClick(){
		   if(this.active == 'dist'){
			   this.$refs.c_table.storageType = 2 
			  this.$refs.c_table.cardName =  '耗材派发单'
		   }else{
			   this.$refs.c_table.storageType = 3
			   this.$refs.c_table.cardName =  '耗材退库单'
		   }
		   this.$refs.c_table.getData();
		},	
   }

}
</script>

</style>